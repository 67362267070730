import React from "react"
import { graphql } from "gatsby"

import { GalerieTempl } from "../../pageTemplates/galerie"

const Galerie = ({ data, location }) => (
  <GalerieTempl data={data} location={location} en />
)

export const query = graphql`
  query {
    strapi {
      galeriePage {
        meta_en {
          description
          title
        }
      }
      galeries {
        cover {
          url
          alternativeText
          caption
          imageFileGray: imageFile {
            childImageSharp {
              fluid(duotone: { highlight: "#ffffff", shadow: "#007c60" }) {
                ...GatsbyImageSharpFluid_withWebp
                aspectRatio
              }
            }
          }
          imageFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
                aspectRatio
              }
            }
          }
        }
        gallery {
          url
          caption
          alternativeText
          imageFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
                aspectRatio
              }
            }
          }
        }
        subtitle
        subtitle_en
        text
        text_en
        title
        title_en
        updated_at
        videoUrl
        order
      }
    }
  }
`

export default Galerie
